import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
  CopyToClipboard,
  DropdownReadonly,
  Input,
  LessonTime,
  Modal,
  showCenteredAlertTwoButtonsWithParameters,
  showTopAlert
} from "../../../../../../../components";
import {RightType, SalesTypeEnum, ServiceErrorMessages, TermStatusName} from "../../../../../../../consts/Constants";
import Portal from "../../../../../../../components/Portal";
import {
  addTimeZone,
  iconForLessonStatus,
  isItSpecified,
  ISOStringToDate2,
  translateWeekDays,
} from "../../../../../../../components/Utility";
import useAuth from "../../../../../../../hooks/Log/useAuth";
import {useLocalization} from "../../../../../../../hooks/Utils/useLocalization";
import {
  GetIsFridayLessonFreeTalk,
  GetLessonCountDetails,
  GetLessonHistory,
  GetLessonTimeAndTeacher,
  GetMemberLessonAppsWithTerm,
  GetTermBeginDateAndDay,
  GetTermEndDateAndDay,
  GetTermRight,
  GetTermStudentName
} from "../../../../../../../services/Term/TermService";
import {
  CallTypeModal,
  LessonAddDeleteModal,
  MissedCallRightModal,
  PostponeRightModal,
  StudentNameModal,
  TeacherTimeChangeModal,
  TeacherTimeChangeRightModal,
  TermStartDateModal
} from "../../components";
import {UpdateIsFridayAndLessonFreeTalk} from "../../../../../../../services/TermSchedule/TermScheduleService";
import {getButtonPermission} from "../../../../../../../rights/Utility";
import classNames from "classnames";

const TermContent = (props) => {

  const {
    isEditable,
    termDetail,
    studentNo,
    isActiveTeacherTimeChangeButton,
    isAutoDemo,
    isActiveLessonAddDeleteButton,
    activeTermEndDate = null
  } = props;

  const strings = useLocalization();
  const { employee, checkPermission, employeeTypeId } = useAuth();
  const language = useSelector(state => state.localizationStore.language);

  const notSpecified = strings.errors.it_is_not_specified;
  const termStatus = TermStatusName.find(status => status.id === termDetail.status);
  const nejatEmployeeIds = [
    "84dd9934-4ff1-407f-8ee3-535634dfa790",
    "c18f228f-c946-422f-0149-08daf3e7575d",
    "79042b19-0d66-4f0e-bdda-08daed916901"
  ];
  const emrahEmployeeId = "bfa6a716-0e36-4b8e-a202-c1526c03eb66";

  const termIdCopyRoles = getButtonPermission({ group: "termContent", name: "termIdCopy" });
  const termIdCopyPermission = checkPermission(termIdCopyRoles, employeeTypeId);

  const lessonAddDeleteButtonRoles = getButtonPermission({ group: "termContent", name: "lessonAddDeleteButton" });
  const lessonAddDeleteButtonPermission = checkPermission(lessonAddDeleteButtonRoles, employeeTypeId) 
    || nejatEmployeeIds.includes(employee?.EmployeeId)
    || emrahEmployeeId === employee?.EmployeeId;
  const additionalRightButtonRoles = getButtonPermission({ group: "termContent", name: "additionalRights" });
  const additionalRightButtonPermission = termDetail?.salesType !== SalesTypeEnum.Demo
      ? checkPermission(additionalRightButtonRoles, employeeTypeId) || emrahEmployeeId === employee?.EmployeeId
      : false;

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [modalSize, setModalSize] = useState({});

  const smallModal = "h-[400px] max-w-[620px]";
  const middleModal = "h-[600px] max-w-[820px]"
  const largeModal = "h-[90vh] max-w-[1440px]"

  const [termStartDate, setTermStartDate] = useState(new Date());
  const [termStartText, setTermStartText] = useState("");
  const [termEndDate, setTermEndDate] = useState(new Date());
  const [termEndText, setTermEndText] = useState("");
  const [callTypeList, setCallTypeList] = useState([]);
  const [studentName, setStudentName] = useState('');
  const [lessonInfo, setLessonInfo] = useState('');
  const [attendedLessonCount, setAttendedLessonCount] = useState(0);
  const [totalLessonCount, setTotalLessonCount] = useState(0);
  const [remainingLessonCount, setRemainingLessonCount] = useState(0);
  const [postponeRightCount, setPostponeRightCount] = useState(0);
  const [teacherTimeChangeRightCount, setTeacherTimeChangeRightCount] = useState(0);
  const [missedCallRightCount, setMissedCallRightCount] = useState(0);
  const [lessonHistory, setLessonHistory] = useState([]);
  const [isFridayLessonFreeTalk, setIsFridayLessonFreeTalk] = useState();

  // Eğer dönem oluşturulduktan sonra 2 ay geçmişse bu fonksiyon true döndürür. 
  // Dönem başlangıç tarihi güncelleme butonunun görüntülenip görüntülenmemesini kontrol etmek için kullanılır
  const isPassedTwoMonth = () => {
    const today = new Date();
    const differenceDate = activeTermEndDate !== null
      ? new Date(activeTermEndDate)
      : new Date(termDetail.createDate);

    const dayDifference = today.getTime() / (1000 * 60 * 60 * 24) - differenceDate.getTime() / (1000 * 60 * 60 * 24);
    return dayDifference > 60;
  }

  //Dönem başlangıç tarihi için servis
  const getTermBeginDateAndDay = async (termId) => {
    const result = await GetTermBeginDateAndDay(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      let tmpText = ISOStringToDate2(resultContent.date) + " / " + translateWeekDays(resultContent.dayOfDate, language);
      setTermStartText(termStartText => tmpText);
      setTermStartDate(termStartDate => new Date(resultContent.date));
    } else {
      setTermStartDate(termStartDate => new Date(termDetail.beginDate));
      let tmpText = ISOStringToDate2(termStartDate) + " / " + termDetail.beginDateDay;
      setTermStartText(termStartText => tmpText);
    }
  }

  const sendRequestToBeginDateAndDayEndpoint = () => {
    if (termDetail) {
      getTermBeginDateAndDay(termDetail.termId);
    }
  }

  //Dönem başlangıç tarihi için servis
  const getTermEndDateAndDay = async (termId) => {

    const result = await GetTermEndDateAndDay(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      let tmpText = ISOStringToDate2(resultContent.date) + " / " + translateWeekDays(resultContent.dayOfDate, language);
      setTermEndText(termEndText => tmpText);
      setTermEndDate(termEndText => new Date(resultContent.beginDate));
    } else {
      setTermEndDate(termEndDate => new Date(termDetail.endDate));
      let tmpText = ISOStringToDate2(termEndDate) + " / " + termDetail.endDateDay;
      setTermEndText(termEndText => tmpText);
    }
  }

  const sendRequestToEndDateAndDayEndpoint = () => {
    if (termDetail) {
      getTermEndDateAndDay(termDetail.termId);
    }
  }


  //Arama tipleri için servis
  const getSelectedCallTypes = async (termId, memberId) => {
    const result = await GetMemberLessonAppsWithTerm(termId, memberId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      let arrayList = [];
      resultContent?.map((item) => {
        if (item.checkTermLessonApp && item.termLessonAppStatus === "1") {
          arrayList.push(item);
        }
      })

      let activeList = [];
      arrayList?.map((item) => {
        activeList.push({ id: item.id, name: isItSpecified(item.applicationTitle, notSpecified) + " / " + isItSpecified(item.deviceName, notSpecified) })
      })

      setCallTypeList(callTypeList => activeList);
    }
  }

  const sendRequestToCallTypeEndpoint = () => {
    if (termDetail) {
      getSelectedCallTypes(termDetail.termId, termDetail.memberId);
    }
  }

  //Öğrenci adı için servis
  const getTermStudentName = async (termId) => {
    const result = await GetTermStudentName(termId);

    if (result.status === 200) {
      setStudentName(studentName => result.content);
    } else {
      setStudentName(studentName => termDetail.studentName);
    }
  }

  const sendRequestToNameEndpoint = () => {
    if (termDetail) {
      getTermStudentName(termDetail.termId);
    }
  }

  //Ders öğretmenini ve ders saatini getiren servis
  const getLessonTimeAndTeacher = async (termId) => {
    const result = await GetLessonTimeAndTeacher(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      let lessonTeacher = resultContent.teacherName ? resultContent.teacherName : notSpecified;
      let lessonTime = resultContent.lessonTime ? addTimeZone(resultContent.lessonTime, employee.TimeZone) : notSpecified;

      let tmpText = lessonTeacher + " / " + lessonTime;
      setLessonInfo(lessonInfo => tmpText);
    }
  }

  const sendRequestToLessonTimeAndTeacherEndpoint = () => {
    if (termDetail) {
      getLessonTimeAndTeacher(termDetail.termId);
    }
  }

  //Toplam ve kalan ders bilgileri için servis
  const getLessonCountDetails = async (termId) => {
    const result = await GetLessonCountDetails(termId);
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setTotalLessonCount(totalLessonCount => resultContent.totalLesson);
      setRemainingLessonCount(remainingLessonCount => resultContent.remainingLesson);
      setAttendedLessonCount(attendedLessonCount => resultContent.attendedLesson);
    }
  }

  const sendRequestToLessonCountEndpoint = () => {
    if (termDetail) {
      getLessonCountDetails(termDetail.termId);
    }
  }

  //Ders erteleme hakkı için servis
  const getPostponeRight = async (termId, rightTypeId) => {
    const result = await GetTermRight(termId, rightTypeId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setPostponeRightCount(postponeRightCount => resultContent.right);

    } else {
      setPostponeRightCount(postponeRightCount => termDetail.termRightModel.postponeRight);
    }
  }

  const sendRequestToPostponeRightEndpoint = () => {
    if (termDetail) {
      getPostponeRight(termDetail.termId, RightType.Postpone);
    }
  }

  //Eğitmen/Saat değişikliği hakkı için servis
  const getTeacherTimeChangeRight = async (termId, rightTypeId) => {
    const result = await GetTermRight(termId, rightTypeId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setTeacherTimeChangeRightCount(teacherTimeChangeRightCount => resultContent.right);
    } else {
      setTeacherTimeChangeRightCount(teacherTimeChangeRightCount => termDetail.termRightModel.teacherHourChangeRight);
    }
  }

  const sendRequestToTeacherTimeChangeRightEndpoint = () => {
    if (termDetail) {
      getTeacherTimeChangeRight(termDetail.termId, RightType.TeacherHourChange);
    }
  }

  //Dersimi kaçırdım hakkı için servis
  const getMissedCallRight = async (termId, rightTypeId) => {
    const result = await GetTermRight(termId, rightTypeId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setMissedCallRightCount(missedCallRightCount => resultContent.right);
    } else {
      setMissedCallRightCount(missedCallRightCount => termDetail.termRightModel.missedCallRight);
    }
  }

  const sendRequestToMissedCallRightEndpoint = () => {
    if (termDetail) {
      getMissedCallRight(termDetail.termId, RightType.Missed);
    }
  }

  //Ders geçmişini getiren servis
  const getLessonHistory = async (termId) => {
    const result = await GetLessonHistory(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setLessonHistory(lessonHistory => resultContent.lessonStatusItems);
    }
  }

  const sendRequestToLessonHistoryEndpoint = () => {
    if (termDetail) {
      getLessonHistory(termDetail.termId);
    }
  }

  //Öğrencinin cuma günü derslerinin free talk olup olmadığını getiren fonksiyon.
  const getIsFridayLessonFreeTalk = async (termId) => {
    const result = await GetIsFridayLessonFreeTalk(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setIsFridayLessonFreeTalk(isFridayLessonFreeTalk => resultContent)
    }
  }

  const updateIsFridayAndLessonFreeTalk = async (termId, isFridayLessonFreeTalk, createUserId) => {
    let model = {
      termId: termId,
      isFridayLessonFreeTalk: isFridayLessonFreeTalk,
      createUserId: createUserId
    }

    const result = await UpdateIsFridayAndLessonFreeTalk(model);

    if (result.status === 200) {
        showTopAlert(strings.member.member_detail.terms.operation_modal.update_unit_success, "success");
        setTimeout(() => { setIsFridayLessonFreeTalk(isFridayLessonFreeTalk => !isFridayLessonFreeTalk); sendRequestToFridayEndpoint();}, 1000);
    } else {
        if(ServiceErrorMessages.UPDATE_FREE_TALK_ACTIVE_TERM_ERROR === result.content){
          showTopAlert(strings.member.member_detail.terms.friday_error_messages.active_term_schedule_error, "warning");
        } else if (ServiceErrorMessages.UPDATE_FREE_TALK_TIME_RANGE_ERROR === result.content) {
          showTopAlert(strings.member.member_detail.terms.friday_error_messages.time_range_error, "error");
        } else {
          showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }
  }

  const sendRequestToFridayEndpoint = () => {
    if (termDetail) {
      getIsFridayLessonFreeTalk(termDetail.termId);
    }
  }

  const closeButton = () => {
    setModal(false);
  }

  useEffect(() => {
    sendRequestToBeginDateAndDayEndpoint();
    sendRequestToEndDateAndDayEndpoint();
    sendRequestToCallTypeEndpoint();
    sendRequestToNameEndpoint();
    sendRequestToLessonCountEndpoint();
    sendRequestToLessonTimeAndTeacherEndpoint();
    sendRequestToPostponeRightEndpoint();
    sendRequestToTeacherTimeChangeRightEndpoint();
    sendRequestToMissedCallRightEndpoint();
    sendRequestToLessonHistoryEndpoint();
    sendRequestToFridayEndpoint();
  }, [])

  return (
    <>
      <div className="w-full flex flex-col md:gap-4">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <div className="flex items-center gap-1">
                <label className="text-[13px] font-bold mb-2" htmlFor="term_id">{strings.member.member_detail.terms.term_id}</label>
                {termIdCopyPermission && <CopyToClipboard text={termDetail.termId} className="mb-1" />}
              </div>
              <input
                  className={classNames("form-input w-full", {
                    "select-none opacity-70 bg-[#f5f5f5] cursor-not-allowed" : !termIdCopyPermission
                  })}
                  readOnly={true}
                  value={termDetail.termId}
                  onMouseDown={(e) => !termIdCopyPermission && e.preventDefault()}
                  onFocus={(e) => !termIdCopyPermission && e.preventDefault()}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="term_begin_date"
              >
                {strings.member.member_detail.terms.term_start_date}
              </label>
              <Input
                id="term_begin_date"
                type="text"
                value={termStartText}
                isReadOnly={true}
              />
              {/* En az bir dersini kaçırdıysa veya bir derse katıldıysa güncelleme modalını açmamak için ikonu göstermiyoruz */}
              {(isEditable) && !((termDetail?.lessonCountModel?.missedLesson >= 1) || (+attendedLessonCount >= 1)) && !isPassedTwoMonth() && (
                <span
                  onClick={() => {
                    setModal(true);
                    setModalContent({ element: "termstart" });
                    setModalSize({ size: "small" });
                  }}
                  className="absolute right-4 top-[calc(50%+2px)] material-symbols-outlined text-blue cursor-pointer"
                >
                  edit_note
                </span>
              )}
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="product_name"
              >
                {strings.member.member_detail.terms.package_name}
              </label>
              <Input
                id="product_name"
                type="text"
                defaultValue={termDetail.productName}
                isReadOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2" htmlFor="call_type">
                {strings.member.member_detail.terms.call_type}
              </label>
              <DropdownReadonly
                data={callTypeList}
                title={callTypeList.length > 0 ? strings.member.member_detail.terms.click_for_show_call_type : strings.member.member_detail.terms.call_type_not_added}
                arrowClassnames="!right-8"
              />
            </div>
            {(isEditable) && (
              <span
                onClick={() => {
                  setModal(true);
                  setModalContent({ element: "calltype" });
                  setModalSize({ size: "middle" });
                }}
                className="absolute right-4 top-[calc(50%+2px)] material-symbols-outlined text-blue cursor-pointer"
              >
                edit_note
              </span>
            )}
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="term_end_date"
              >
                {strings.member.member_detail.terms.term_end_date}
              </label>
              <Input
                id="term_end_date"
                type="text"
                value={termEndText}
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="student_name"
              >
                {strings.member.member_detail.terms.student_name}
              </label>
              <Input
                id="student_name"
                type="text"
                value={studentName}
                isReadOnly={true}
              />
            </div>
            {isEditable && (
              <span
                onClick={() => {
                  setModal(true);
                  setModalContent({ element: "student" });
                  setModalSize({ size: "small" });
                }}
                className="absolute right-4 top-[calc(50%+2px)] material-symbols-outlined text-blue cursor-pointer"
              >
                edit_note
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="teacher_time_change"
              >
                {strings.member.member_detail.terms.teacher_time_change}
              </label>
              <Input
                id="teacher_time_change"
                type="text"
                value={lessonInfo}
                isReadOnly={true}
              />
            </div>
            {(isActiveTeacherTimeChangeButton && !isAutoDemo) && (
              <span
                onClick={() => {
                  setModal(true);
                  setModalContent({ element: "teacherchange" });
                  setModalSize({ size: "large" });
                }}
                className="absolute right-4 top-[calc(50%+2px)] material-symbols-outlined text-blue cursor-pointer"
              >
                edit_note
              </span>
            )}
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="term_status"
              >
                {strings.member.member_detail.terms.term_status}
              </label>
              <Input
                id="term_status"
                type="text"
                defaultValue={termStatus[language]}
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0 relative">
              <label className="text-[13px] font-bold mb-2" htmlFor="total_lesson">
                {strings.member.member_detail.terms.total_lesson}
              </label>
              <Input
                id="total_lesson"
                type="text"
                value={totalLessonCount}
                isReadOnly={true}
              />
              {(isActiveLessonAddDeleteButton && lessonAddDeleteButtonPermission) && (
                <div className="absolute right-4 top-[calc(50%+2px)]">
                  <div className="relative group">
                    <span
                      onClick={() => {
                        setModal(true);
                        setModalContent({ element: "lessonadddelete" });
                        setModalSize({ size: "middle" });
                      }}
                      className="material-symbols-outlined text-blue cursor-pointer"
                    >
                      edit_note
                    </span>
                    <div className="tooltip-top !-top-[35px]">
                      {strings.member.member_detail.terms.lesson_add_delete}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="attended_lesson"
              >
                {strings.member.member_detail.terms.attended_lesson_count}
              </label>
              <Input
                id="attended_lesson"
                type="text"
                value={attendedLessonCount}
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="remaining_lesson"
              >
                {strings.member.member_detail.terms.remaining_lesson_count}
              </label>
              <Input
                id="remaining_lesson"
                type="text"
                value={remainingLessonCount}
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="missed_lesson"
              >
                {strings.member.member_detail.terms.missed_call_count}
              </label>
              <Input
                id="missedLesson"
                type="text"
                defaultValue={termDetail.lessonCountModel.missedLesson}
                isReadOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="freeze_right"
              >
                {strings.member.member_detail.terms.freeze_lesson_count}
              </label>
              <Input
                id="freeze_right"
                type="text"
                defaultValue={termDetail.termRightModel.freezeRight}
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="postpone_right">
                {strings.member.member_detail.terms.postpone_lesson_right}
              </label>
              <Input
                id="postpone_right"
                type="text"
                value={postponeRightCount}
                isReadOnly={true}
              />
            </div>
            {(isEditable && additionalRightButtonPermission) && (
              <span
                onClick={() => {
                  setModal(true);
                  setModalContent({ element: "postpone" });
                  setModalSize({ size: "small" });
                }}
                className="absolute right-4 top-[calc(50%+2px)] material-symbols-outlined text-blue cursor-pointer"
              >
                edit_note
              </span>
            )}
          </div>
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="teacher_hour_change_right"
              >
                {strings.member.member_detail.terms.teacher_time_change_right}
              </label>
              <Input
                id="teacher_hour_change_right"
                type="text"
                value={teacherTimeChangeRightCount}
                isReadOnly={true}
              />
            </div>
            {(isEditable && additionalRightButtonPermission) && (
              <span
                onClick={() => {
                  setModal(true);
                  setModalContent({ element: "teacherright" });
                  setModalSize({ size: "small" });
                }}
                className="absolute right-4 top-[calc(50%+2px)] material-symbols-outlined text-blue cursor-pointer"
              >
                edit_note
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="missed_call_right"
              >
                {strings.member.member_detail.terms.missed_call_right}
              </label>
              <Input
                id="missed_call_right"
                type="text"
                value={missedCallRightCount}
                isReadOnly={true}
              />
            </div>
            {(isEditable && additionalRightButtonPermission) && (
              <span
                onClick={() => {
                  setModal(true);
                  setModalContent({ element: "missedcall" });
                  setModalSize({ size: "small" });
                }}
                className="absolute right-4 top-[calc(50%+2px)] material-symbols-outlined text-blue cursor-pointer"
              >
                edit_note
              </span>
            )}
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="term_level"
              >
                {strings.member.member_detail.terms.level}
              </label>
              <Input
                id="term_level"
                type="text"
                defaultValue={termDetail.termLevel ? termDetail.termLevel : notSpecified}
                isReadOnly={true}
              />
            </div>
          </div>
          <div className="mb-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="last_subject"
              >
                {strings.member.member_detail.terms.last_subject}
              </label>
              <Input
                id="last_subject"
                type="text"
                defaultValue={termDetail.lastSubjectOfWeek ? termDetail.lastSubjectOfWeek : notSpecified}
                isReadOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-5 w-full lg:w-[32.5%] relative">
            <div className="flex flex-col px-[10px] pb-0">
              <label
                className="text-[13px] font-bold mb-2"
                htmlFor="is_friday_free_talk"
              >
                {strings.member.member_detail.terms.friday}
              </label>
              <Input
                id="is_friday_free_talk"
                type="text"
                value={isFridayLessonFreeTalk ? strings.member.member_detail.terms.free_talk : strings.member.member_detail.terms.operation_modal.unit}
                isReadOnly={true}
              />
            </div>
            {isEditable && (
              <span
                onClick={() => { 
                  const lessonToChange = isFridayLessonFreeTalk ? strings.member.member_detail.terms.operation_modal.unit : strings.member.member_detail.terms.free_talk || ""
                  let messageContent = strings.member.member_detail.terms.modal.is_friday_update_question.replace(/#fridaylesson#/g, lessonToChange);

                  showCenteredAlertTwoButtonsWithParameters(
                    strings.alert.centered_alert_two_buttons.title_confirm,
                    messageContent,
                    strings.button.confirm,
                    strings.button.cancel,
                    updateIsFridayAndLessonFreeTalk,
                    [termDetail.termId, !isFridayLessonFreeTalk, employee.EmployeeId]
                  );
                }}
                className="absolute right-4 top-[calc(50%+2px)] material-symbols-outlined text-blue cursor-pointer"
              >
                edit_note
              </span>
            )}
          </div>
        </div>
        {lessonHistory.length > 0 && (
          <div>
            <p className="text-[13px] font-bold mb-2 ml-2">
              {strings.member.member_detail.terms.lesson_history}
            </p>
            <div className="flex flex-wrap mt-5 gap-x-4 gap-y-2 mx-2">
              {lessonHistory.length > 0 && lessonHistory.map((lesson, index) => {
                const icon = iconForLessonStatus(lesson.status, language);
                return (
                  <LessonTime
                    key={index}
                    tooltip={icon.tooltip}
                    iconName={icon.iconName}
                    iconClassnames={icon.color}
                    date={lesson.date}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
      {/* Portal kullanarak TermContent componentinde bulunan modalları bir üst componente taşıyarak modal'ın üste çıkmasını sağladık */}
      <Portal container={document.getElementById('modal-container')}>
        {(Object.keys(modalContent).length !== 0 && (
          <Modal
            modal={modal}
            setModal={setModal}
            classes={{
              modal: modalSize.size === 'small' ? smallModal : modalSize.size === 'middle' ? middleModal : largeModal
            }}
          >
            {modalContent.element === "termstart" && modalSize.size === "small" &&
              <TermStartDateModal
                date={termStartDate}
                termId={termDetail.termId}
                createDate={termDetail.createDate}
                activeTermEndDate={activeTermEndDate}
                sendRequestToBeginDateAndDayEndpoint={sendRequestToBeginDateAndDayEndpoint}
                employeeTypeId={employeeTypeId}
                closeButton={closeButton}
              />
            }
            {modalContent.element === "calltype" && modalSize.size === "middle" &&
              <CallTypeModal
                termId={termDetail.termId}
                memberId={termDetail.memberId}
                studentNo={studentNo}
                sendRequestToCallTypeEndpoint={sendRequestToCallTypeEndpoint}
              />
            }
            {modalContent.element === "student" && modalSize.size === "small" &&
              <StudentNameModal
                termId={termDetail.termId}
                memberId={termDetail.memberId}
                name={studentName}
                sendRequestToNameEndpoint={sendRequestToNameEndpoint}
                closeButton={closeButton}
              />
            }
            {modalContent.element === "teacherchange" && modalSize.size === "large" &&
              <TeacherTimeChangeModal
                termId={termDetail.termId}
                sendRequestToLessonTimeAndTeacherEndpoint={sendRequestToLessonTimeAndTeacherEndpoint}
                closeButton={closeButton}
                lessonInfo={lessonInfo}
                classes="!h-[90vh] !max-w-[1140px]"
              />
            }
            {modalContent.element === "lessonadddelete" && modalSize.size === "middle" &&
              <LessonAddDeleteModal
                termId={termDetail.termId}
                memberId={termDetail.memberId}
                currentLessonCount={totalLessonCount}
                sendRequestToLessonCountEndpoint={sendRequestToLessonCountEndpoint}
                sendRequestToBeginDateAndDayEndpoint={sendRequestToBeginDateAndDayEndpoint}
                sendRequestToEndDateAndDayEndpoint={sendRequestToEndDateAndDayEndpoint}
                closeButton={closeButton}
              />
            }
            {modalContent.element === "postpone" && modalSize.size === "small" &&
              <PostponeRightModal
                termId={termDetail.termId}
                right={postponeRightCount}
                sendRequestToPostponeRightEndpoint={sendRequestToPostponeRightEndpoint}
                closeButton={closeButton}
              />
            }
            {modalContent.element === "teacherright" && modalSize.size === "small" &&
              <TeacherTimeChangeRightModal
                termId={termDetail.termId}
                right={teacherTimeChangeRightCount}
                sendRequestToTeacherTimeChangeRightEndpoint={sendRequestToTeacherTimeChangeRightEndpoint}
                closeButton={closeButton}
              />
            }
            {modalContent.element === "missedcall" && modalSize.size === "small" &&
              <MissedCallRightModal
                termId={termDetail.termId}
                right={missedCallRightCount}
                sendRequestToMissedCallRightEndpoint={sendRequestToMissedCallRightEndpoint}
                closeButton={closeButton}
              />
            }
          </Modal>
        ))}
      </Portal>
    </>
  );
};

export default TermContent;
